import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { useCallback, VFC } from "react";
import { useParams } from "react-router-dom";
import { useCreateCommentaryMutation } from "../../../graphql/graphql";
import { useLoading } from "../../../hooks/useLoading";
import { useSnack } from "../../../hooks/useSnacks";
import { SimpleButton } from "../../atoms/SimpleButton";
import { SimpleTextField } from "../../atoms/SimpleTextField";
import { initialValues, schema, Values } from "./values";

type P = {
  afterSearch: () => void;
};

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  pageField: {
    alignSelf: "start",
  },
  submitContainer: {
    textAlignLast: "end",
  },
}));

export const CommentaryForm: VFC<P> = ({ afterSearch }) => {
  const classes = useStyles();
  const [createCommentary, { loading }] = useCreateCommentaryMutation();
  const { isbn } = useParams<{ isbn: string }>();
  const { successSnack } = useSnack();

  const submit = useCallback((v: Values) => {
    void createCommentary({ variables: { ...v, isbn } }).then(() => {
      afterSearch();
      successSnack("解説・考察を作成しました！");
    });
  }, []);

  useLoading(loading);

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item className={classes.titleContainer}>
          <Typography align={"left"}>解説・考察を投稿しよう！</Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(v, { setSubmitting, resetForm }) => {
              setSubmitting(false);
              submit(v);
              resetForm();
            }}
          >
            {({ errors }) => (
              <Grid container component={Form} direction={"column"} spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item className={classes.pageField}>
                    <SimpleTextField name={"page"} label={"該当のページ"} />
                  </Grid>
                  <Grid item className={classes.pageField}>
                    <SimpleTextField
                      name={"columnOrRow"}
                      label={"行・列"}
                      type={"number"}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SimpleTextField
                    name={"targetString"}
                    label={"解説・考察対象の文章"}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item>
                  <SimpleTextField
                    fullWidth
                    name={"commentaryString"}
                    label={"解説・考察"}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item className={classes.submitContainer}>
                  <SimpleButton
                    color="primary"
                    variant={"contained"}
                    type={"submit"}
                    disabled={Object.keys(errors).length !== 0}
                  >
                    投稿
                  </SimpleButton>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};
