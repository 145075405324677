import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { config } from "dotenv";
import { FC } from "react";
import { appHistory } from "./index";

config();

export const AuthProvider: FC = ({ children }) => {
  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (
    appState
  ) => {
    // Use the router's appHistory module to replace the url
    appHistory.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={"https://review-backend"}
    >
      {children}
    </Auth0Provider>
  );
};
