import { User } from "@auth0/auth0-react";
import { Grid, Popover, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { memo, useRef, useState, VFC } from "react";
import { Panel } from "./Panel";

type P = {
  user: User;
};

const useStyles = makeStyles((theme) => ({
  accountStr: {
    fontWeight: theme.typography.fontWeightBold,
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

export const ProfilePanel: VFC<P> = memo(({ user }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchor = useRef(null);

  return (
    <>
      <Grid
        ref={anchor}
        container
        direction={"column"}
        justify={"flex-start"}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={(e) => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        <Grid item>
          <Typography>{user.name}さん</Typography>
        </Grid>
        <Grid item>
          <Typography align={"left"} className={classes.accountStr}>
            アカウント
          </Typography>
        </Grid>
      </Grid>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchor.current}
        classes={{
          paper: classes.popoverContent,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disableRestoreFocus
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          onMouseEnter: () => {
            setOpen(true);
          },
          onMouseLeave: () => {
            setOpen(false);
          },
        }}
      >
        <Panel />
      </Popover>
    </>
  );
});
