import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { VFC } from "react";
import { useParams } from "react-router-dom";
import { useCommentariesQuery } from "../../../graphql/graphql";
import { CommentaryCard } from "../../organisms/CommentaryCard";
import { CommentaryForm } from "../../organisms/CommentaryForm";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(2),
  },
  formContainer: {
    width: "inherit",
    marginBottom: theme.spacing(3),
  },
}));

export const CommentaryTab: VFC = () => {
  const classes = useStyles();
  const { isbn } = useParams<{ isbn: string }>();
  const { data, refetch } = useCommentariesQuery({
    variables: { isbn },
  });

  const afterSearch = () => refetch();

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item className={classes.formContainer} xs={12}>
        <CommentaryForm afterSearch={afterSearch} />
      </Grid>
      <Grid item container direction={"column"} spacing={2}>
        {data?.commentaries.map((c) => (
          <Grid item key={c.id}>
            <CommentaryCard commentary={c} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
