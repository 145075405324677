import { Selector } from "@reduxjs/toolkit";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../module/store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSelectors = <
  T extends { [key: string]: Selector<RootState, unknown, any> },
  M extends (keyof T)[]
>(
  selectors: T,
  ...memoizer: M
) => {
  const s = Object.keys(selectors)
    .filter((k) => memoizer.includes(k))
    .reduce(
      (prev, key) => (s: RootState) => ({
        ...prev(s),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [key]: selectors[key](s),
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_: RootState) => ({} as { [key in M[number]]: ReturnType<T[key]> })
    );

  return useSelector(s, (a, b) =>
    memoizer.length !== 0
      ? _.isEqual(_.pick(a, memoizer), _.pick(b, memoizer))
      : _.isEqual(a, b)
  );
};
