import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectProps,
} from "@material-ui/core";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import React, { VFC } from "react";

type P = {
  name: string;
  items: { value: string | number; label: string }[];
  width: number;
} & SelectProps;

export const SimpleSelect: VFC<P> = ({ name, items, width, ...props }) => {
  return (
    <FormControl variant={"outlined"} size={"small"}>
      <InputLabel>{props.label}</InputLabel>
      <Field name={name} component={Select} {...props} style={{ width }}>
        {items.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};
