import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { VFC } from "react";
import { useParams } from "react-router-dom";
import { useBookQuery } from "../../../graphql/graphql";
import { useLoading } from "../../../hooks/useLoading";
import { ReviewTab } from "./ReviewTab";

const useStyles = makeStyles((theme) => ({
  img: {
    height: 400,
  },
  pageContainer: {
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
  },
  reviewContainer: {
    marginTop: theme.spacing(3),
  },
}));

export const BookDetailPage: VFC = () => {
  const { isbn } = useParams<{ isbn: string }>();
  const { data, loading } = useBookQuery({
    variables: { isbn },
  });
  const classes = useStyles();

  useLoading(loading);

  return (
    <Grid container className={classes.pageContainer}>
      {data && (
        <>
          <Grid item xs={4}>
            <img src={data.book.imageUrl} className={classes.img} />
          </Grid>
          <Grid item xs={8} container direction={"column"}>
            <Grid item>
              <Typography variant={"h4"} align={"left"}>
                {data.book.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align={"left"} variant={"h5"}>
                <Link
                  href={`/search_books?sort=Standard&authorName=${data.book.authorName}&page=1`}
                >
                  {data.book.authorName}
                </Link>
              </Typography>
              <Typography align={"left"}>ISBN: {data.book.isbn}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.reviewContainer}>
            <ReviewTab isbn={isbn} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
