import { SnackbarKey, useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useBoundActions } from "../../../hooks/useBoundActions";
import { snackbarSelectors } from "../../../module/snackbar/selector";
import { snackbarActions } from "../../../module/snackbar/slice";

export const Notifier: React.FC = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { removeSnackbar } = useBoundActions(snackbarActions);
  const notifications = useSelector(snackbarSelectors.notifications);
  const [displayed, setDisplayed] = useState<SnackbarKey[]>([]);

  const storeDisplayed = (key: SnackbarKey) => {
    setDisplayed((s) => [...s, key]);
  };

  useEffect(() => {
    notifications.forEach((notification) =>
      setTimeout(() => {
        if (displayed.indexOf(notification.key) > -1) return;
        // Display notification using notistack
        enqueueSnackbar(notification.message, notification.options);
        // Add notification's key to the local state
        storeDisplayed(notification.key);
        // Dispatch action to remove the notification from the redux store
        removeSnackbar(notification.key);
      }, 1)
    );
  }, [notifications]);

  return <></>;
};
