import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { VFC } from "react";
import { useUrl } from "../../../hooks/useUrl";

type P = {
  book: {
    isbn: string;
    title: string;
    imageUrl: string;
    authorName: string;
  };
};

const useStyles = makeStyles({
  cardContainer: {
    width: 230,
    minHeight: 400,
  },
  img: {
    width: 220,
    height: 300,
  },
  title: {
    textAlign: "start",
  },
  author: {
    textAlign: "start",
  },
});

export const BookSearchResultCard: VFC<P> = ({ book }) => {
  const classes = useStyles();
  const { goToBookDetail } = useUrl();

  return (
    <Card
      variant={"outlined"}
      className={classes.cardContainer}
      onClick={() => goToBookDetail(book.isbn)}
    >
      <CardActionArea disableRipple>
        <img src={book.imageUrl} className={classes.img} />
        <CardContent>
          <Typography className={classes.title}>{book.title}</Typography>
          <Typography color={"textSecondary"} className={classes.author}>
            {book.authorName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
