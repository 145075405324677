import { SearchSort } from "../../../graphql/graphql";

export type Sort =
  | "Standard"
  | "Sales"
  | "ReleaseDateAsc"
  | "ReleaseDateDesc"
  | "PriceAsc"
  | "PriceDesc";

export type Values = {
  title?: string;
  author?: string;
  publisherName?: string;
  isbn?: string;
  sort: SearchSort;
};

export const initialValues: Values = {
  sort: SearchSort.Standard,
};
