import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time without a time-zone in the ISO-8601 calendar system in the format of 1970-01-01T00:00:00 */
  LocalDateTime: string;
  Unit: void;
};

export type Book = {
  __typename?: 'Book';
  authorName: Scalars['String'];
  caption: Scalars['String'];
  imageUrl: Scalars['String'];
  isbn: Scalars['String'];
  price: Scalars['Int'];
  publisherName: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type BookSearchResult = {
  __typename?: 'BookSearchResult';
  books: Array<Book>;
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type Commentary = {
  __typename?: 'Commentary';
  columnOrRow: Scalars['Int'];
  commentaryString: Scalars['String'];
  createdAt: Scalars['LocalDateTime'];
  id: Scalars['String'];
  isbn: Scalars['String'];
  page: Scalars['String'];
  targetString: Scalars['String'];
  userSub: Scalars['String'];
};

export type Mutations = {
  __typename?: 'Mutations';
  createCommentary: Commentary;
  createProofreading: Proofreading;
  deleteCommentary: Scalars['Unit'];
  deleteProofreading: Scalars['Unit'];
};


export type MutationsCreateCommentaryArgs = {
  columnOrRow: Scalars['Int'];
  commentaryString: Scalars['String'];
  isbn: Scalars['String'];
  page: Scalars['String'];
  targetString: Scalars['String'];
};


export type MutationsCreateProofreadingArgs = {
  columnOrRow: Scalars['Int'];
  isbn: Scalars['String'];
  originalString: Scalars['String'];
  page: Scalars['String'];
  proofedString: Scalars['String'];
};


export type MutationsDeleteCommentaryArgs = {
  id: Scalars['String'];
};


export type MutationsDeleteProofreadingArgs = {
  id: Scalars['String'];
};

export type Proofreading = {
  __typename?: 'Proofreading';
  columnOrRow: Scalars['Int'];
  createdAt: Scalars['LocalDateTime'];
  id: Scalars['String'];
  isbn: Scalars['String'];
  originalString: Scalars['String'];
  page: Scalars['String'];
  proofedString: Scalars['String'];
  userSub: Scalars['String'];
};

export type Queries = {
  __typename?: 'Queries';
  book: Book;
  books: BookSearchResult;
  commentaries: Array<Commentary>;
  proofReadings: Array<Proofreading>;
};


export type QueriesBookArgs = {
  isbn: Scalars['String'];
};


export type QueriesBooksArgs = {
  authorName?: Maybe<Scalars['String']>;
  isbn?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  publisherName?: Maybe<Scalars['String']>;
  sort: SearchSort;
  title?: Maybe<Scalars['String']>;
};


export type QueriesCommentariesArgs = {
  isbn: Scalars['String'];
};


export type QueriesProofReadingsArgs = {
  isbn: Scalars['String'];
};

export const SearchSort = {
  PriceAsc: 'PriceAsc',
  PriceDesc: 'PriceDesc',
  ReleaseDateAsc: 'ReleaseDateAsc',
  ReleaseDateDesc: 'ReleaseDateDesc',
  Sales: 'Sales',
  Standard: 'Standard'
} as const;

export type SearchSort = typeof SearchSort[keyof typeof SearchSort];
export type CreateCommentaryMutationVariables = Exact<{
  page: Scalars['String'];
  targetString: Scalars['String'];
  commentaryString: Scalars['String'];
  isbn: Scalars['String'];
  columnOrRow: Scalars['Int'];
}>;


export type CreateCommentaryMutation = { __typename?: 'Mutations', createCommentary: { __typename?: 'Commentary', id: string, targetString: string, commentaryString: string, isbn: string, columnOrRow: number } };

export type CreateProofreadingMutationVariables = Exact<{
  page: Scalars['String'];
  originalString: Scalars['String'];
  proofedString: Scalars['String'];
  isbn: Scalars['String'];
  columnOrRow: Scalars['Int'];
}>;


export type CreateProofreadingMutation = { __typename?: 'Mutations', createProofreading: { __typename?: 'Proofreading', id: string, originalString: string, proofedString: string, isbn: string, page: string, columnOrRow: number } };

export type DeleteCommentaryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCommentaryMutation = { __typename?: 'Mutations', deleteCommentary: void };

export type DeleteProofreadingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProofreadingMutation = { __typename?: 'Mutations', deleteProofreading: void };

export type BookQueryVariables = Exact<{
  isbn: Scalars['String'];
}>;


export type BookQuery = { __typename?: 'Queries', book: { __typename?: 'Book', title: string, authorName: string, isbn: string, imageUrl: string } };

export type BooksQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  publisherName?: Maybe<Scalars['String']>;
  isbn?: Maybe<Scalars['String']>;
  sort: SearchSort;
  page: Scalars['Int'];
}>;


export type BooksQuery = { __typename?: 'Queries', books: { __typename?: 'BookSearchResult', pageCount: number, page: number, books: Array<{ __typename?: 'Book', title: string, authorName: string, isbn: string, imageUrl: string }> } };

export type CommentariesQueryVariables = Exact<{
  isbn: Scalars['String'];
}>;


export type CommentariesQuery = { __typename?: 'Queries', commentaries: Array<{ __typename?: 'Commentary', id: string, targetString: string, commentaryString: string, page: string, isbn: string, columnOrRow: number, createdAt: string, userSub: string }> };

export type ProofreadingsQueryVariables = Exact<{
  isbn: Scalars['String'];
}>;


export type ProofreadingsQuery = { __typename?: 'Queries', proofReadings: Array<{ __typename?: 'Proofreading', id: string, originalString: string, proofedString: string, page: string, isbn: string, columnOrRow: number, createdAt: string, userSub: string }> };


export const CreateCommentaryDocument = gql`
    mutation createCommentary($page: String!, $targetString: String!, $commentaryString: String!, $isbn: String!, $columnOrRow: Int!) {
  createCommentary(
    page: $page
    targetString: $targetString
    commentaryString: $commentaryString
    isbn: $isbn
    columnOrRow: $columnOrRow
  ) {
    id
    targetString
    commentaryString
    isbn
    columnOrRow
  }
}
    `;
export type CreateCommentaryMutationFn = Apollo.MutationFunction<CreateCommentaryMutation, CreateCommentaryMutationVariables>;

/**
 * __useCreateCommentaryMutation__
 *
 * To run a mutation, you first call `useCreateCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentaryMutation, { data, loading, error }] = useCreateCommentaryMutation({
 *   variables: {
 *      page: // value for 'page'
 *      targetString: // value for 'targetString'
 *      commentaryString: // value for 'commentaryString'
 *      isbn: // value for 'isbn'
 *      columnOrRow: // value for 'columnOrRow'
 *   },
 * });
 */
export function useCreateCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentaryMutation, CreateCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentaryMutation, CreateCommentaryMutationVariables>(CreateCommentaryDocument, options);
      }
export type CreateCommentaryMutationHookResult = ReturnType<typeof useCreateCommentaryMutation>;
export type CreateCommentaryMutationResult = Apollo.MutationResult<CreateCommentaryMutation>;
export type CreateCommentaryMutationOptions = Apollo.BaseMutationOptions<CreateCommentaryMutation, CreateCommentaryMutationVariables>;
export const CreateProofreadingDocument = gql`
    mutation createProofreading($page: String!, $originalString: String!, $proofedString: String!, $isbn: String!, $columnOrRow: Int!) {
  createProofreading(
    page: $page
    originalString: $originalString
    proofedString: $proofedString
    isbn: $isbn
    columnOrRow: $columnOrRow
  ) {
    id
    originalString
    proofedString
    isbn
    page
    columnOrRow
  }
}
    `;
export type CreateProofreadingMutationFn = Apollo.MutationFunction<CreateProofreadingMutation, CreateProofreadingMutationVariables>;

/**
 * __useCreateProofreadingMutation__
 *
 * To run a mutation, you first call `useCreateProofreadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProofreadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProofreadingMutation, { data, loading, error }] = useCreateProofreadingMutation({
 *   variables: {
 *      page: // value for 'page'
 *      originalString: // value for 'originalString'
 *      proofedString: // value for 'proofedString'
 *      isbn: // value for 'isbn'
 *      columnOrRow: // value for 'columnOrRow'
 *   },
 * });
 */
export function useCreateProofreadingMutation(baseOptions?: Apollo.MutationHookOptions<CreateProofreadingMutation, CreateProofreadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProofreadingMutation, CreateProofreadingMutationVariables>(CreateProofreadingDocument, options);
      }
export type CreateProofreadingMutationHookResult = ReturnType<typeof useCreateProofreadingMutation>;
export type CreateProofreadingMutationResult = Apollo.MutationResult<CreateProofreadingMutation>;
export type CreateProofreadingMutationOptions = Apollo.BaseMutationOptions<CreateProofreadingMutation, CreateProofreadingMutationVariables>;
export const DeleteCommentaryDocument = gql`
    mutation deleteCommentary($id: String!) {
  deleteCommentary(id: $id)
}
    `;
export type DeleteCommentaryMutationFn = Apollo.MutationFunction<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>;

/**
 * __useDeleteCommentaryMutation__
 *
 * To run a mutation, you first call `useDeleteCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentaryMutation, { data, loading, error }] = useDeleteCommentaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>(DeleteCommentaryDocument, options);
      }
export type DeleteCommentaryMutationHookResult = ReturnType<typeof useDeleteCommentaryMutation>;
export type DeleteCommentaryMutationResult = Apollo.MutationResult<DeleteCommentaryMutation>;
export type DeleteCommentaryMutationOptions = Apollo.BaseMutationOptions<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>;
export const DeleteProofreadingDocument = gql`
    mutation deleteProofreading($id: String!) {
  deleteProofreading(id: $id)
}
    `;
export type DeleteProofreadingMutationFn = Apollo.MutationFunction<DeleteProofreadingMutation, DeleteProofreadingMutationVariables>;

/**
 * __useDeleteProofreadingMutation__
 *
 * To run a mutation, you first call `useDeleteProofreadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProofreadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProofreadingMutation, { data, loading, error }] = useDeleteProofreadingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProofreadingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProofreadingMutation, DeleteProofreadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProofreadingMutation, DeleteProofreadingMutationVariables>(DeleteProofreadingDocument, options);
      }
export type DeleteProofreadingMutationHookResult = ReturnType<typeof useDeleteProofreadingMutation>;
export type DeleteProofreadingMutationResult = Apollo.MutationResult<DeleteProofreadingMutation>;
export type DeleteProofreadingMutationOptions = Apollo.BaseMutationOptions<DeleteProofreadingMutation, DeleteProofreadingMutationVariables>;
export const BookDocument = gql`
    query book($isbn: String!) {
  book(isbn: $isbn) {
    title
    authorName
    isbn
    imageUrl
  }
}
    `;

/**
 * __useBookQuery__
 *
 * To run a query within a React component, call `useBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookQuery({
 *   variables: {
 *      isbn: // value for 'isbn'
 *   },
 * });
 */
export function useBookQuery(baseOptions: Apollo.QueryHookOptions<BookQuery, BookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookQuery, BookQueryVariables>(BookDocument, options);
      }
export function useBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookQuery, BookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookQuery, BookQueryVariables>(BookDocument, options);
        }
export type BookQueryHookResult = ReturnType<typeof useBookQuery>;
export type BookLazyQueryHookResult = ReturnType<typeof useBookLazyQuery>;
export type BookQueryResult = Apollo.QueryResult<BookQuery, BookQueryVariables>;
export function refetchBookQuery(variables?: BookQueryVariables) {
      return { query: BookDocument, variables: variables }
    }
export const BooksDocument = gql`
    query books($title: String, $authorName: String, $publisherName: String, $isbn: String, $sort: SearchSort!, $page: Int!) {
  books(
    title: $title
    authorName: $authorName
    publisherName: $publisherName
    isbn: $isbn
    sort: $sort
    page: $page
  ) {
    books {
      title
      authorName
      isbn
      imageUrl
    }
    pageCount
    page
  }
}
    `;

/**
 * __useBooksQuery__
 *
 * To run a query within a React component, call `useBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksQuery({
 *   variables: {
 *      title: // value for 'title'
 *      authorName: // value for 'authorName'
 *      publisherName: // value for 'publisherName'
 *      isbn: // value for 'isbn'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBooksQuery(baseOptions: Apollo.QueryHookOptions<BooksQuery, BooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BooksQuery, BooksQueryVariables>(BooksDocument, options);
      }
export function useBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BooksQuery, BooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BooksQuery, BooksQueryVariables>(BooksDocument, options);
        }
export type BooksQueryHookResult = ReturnType<typeof useBooksQuery>;
export type BooksLazyQueryHookResult = ReturnType<typeof useBooksLazyQuery>;
export type BooksQueryResult = Apollo.QueryResult<BooksQuery, BooksQueryVariables>;
export function refetchBooksQuery(variables?: BooksQueryVariables) {
      return { query: BooksDocument, variables: variables }
    }
export const CommentariesDocument = gql`
    query commentaries($isbn: String!) {
  commentaries(isbn: $isbn) {
    id
    targetString
    commentaryString
    page
    isbn
    columnOrRow
    createdAt
    userSub
  }
}
    `;

/**
 * __useCommentariesQuery__
 *
 * To run a query within a React component, call `useCommentariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentariesQuery({
 *   variables: {
 *      isbn: // value for 'isbn'
 *   },
 * });
 */
export function useCommentariesQuery(baseOptions: Apollo.QueryHookOptions<CommentariesQuery, CommentariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentariesQuery, CommentariesQueryVariables>(CommentariesDocument, options);
      }
export function useCommentariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentariesQuery, CommentariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentariesQuery, CommentariesQueryVariables>(CommentariesDocument, options);
        }
export type CommentariesQueryHookResult = ReturnType<typeof useCommentariesQuery>;
export type CommentariesLazyQueryHookResult = ReturnType<typeof useCommentariesLazyQuery>;
export type CommentariesQueryResult = Apollo.QueryResult<CommentariesQuery, CommentariesQueryVariables>;
export function refetchCommentariesQuery(variables?: CommentariesQueryVariables) {
      return { query: CommentariesDocument, variables: variables }
    }
export const ProofreadingsDocument = gql`
    query proofreadings($isbn: String!) {
  proofReadings(isbn: $isbn) {
    id
    originalString
    proofedString
    page
    isbn
    columnOrRow
    createdAt
    userSub
  }
}
    `;

/**
 * __useProofreadingsQuery__
 *
 * To run a query within a React component, call `useProofreadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProofreadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProofreadingsQuery({
 *   variables: {
 *      isbn: // value for 'isbn'
 *   },
 * });
 */
export function useProofreadingsQuery(baseOptions: Apollo.QueryHookOptions<ProofreadingsQuery, ProofreadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProofreadingsQuery, ProofreadingsQueryVariables>(ProofreadingsDocument, options);
      }
export function useProofreadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProofreadingsQuery, ProofreadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProofreadingsQuery, ProofreadingsQueryVariables>(ProofreadingsDocument, options);
        }
export type ProofreadingsQueryHookResult = ReturnType<typeof useProofreadingsQuery>;
export type ProofreadingsLazyQueryHookResult = ReturnType<typeof useProofreadingsLazyQuery>;
export type ProofreadingsQueryResult = Apollo.QueryResult<ProofreadingsQuery, ProofreadingsQueryVariables>;
export function refetchProofreadingsQuery(variables?: ProofreadingsQueryVariables) {
      return { query: ProofreadingsDocument, variables: variables }
    }