import { Button, ButtonProps } from "@material-ui/core";
import { FC } from "react";

type P = ButtonProps;

export const SimpleButton: FC<P> = ({ children, ...props }) => {
  return (
    <Button
      disableRipple
      disableFocusRipple
      disableElevation
      disableTouchRipple
      {...props}
    >
      {children}
    </Button>
  );
};
