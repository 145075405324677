import { VFC } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ProtectedRoute } from "../../atoms/ProtectedRoute";
import { BookDetailPage } from "../../pages/BookDetailPage";
import { SearchPage } from "../../pages/SearchPage";

export const AppRouter: VFC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path="/"
        exact
        component={() => <Redirect to={"/search_books"} />}
      />
      <ProtectedRoute path={"/search_books"} component={SearchPage} />
      <ProtectedRoute path={"/books/:isbn"} component={BookDetailPage} />
    </Switch>
  );
};
