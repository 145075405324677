import * as Yup from "yup";

export type Values = {
  page: string;
  columnOrRow: number;
  targetString: string;
  commentaryString: string;
};

export const initialValues: Values = {
  columnOrRow: 1,
  commentaryString: "",
  page: "",
  targetString: "",
};

export const schema = Yup.object().shape({
  page: Yup.string().required("該当のページを入力してください"),
  columnOrRow: Yup.number().min(1, "ページ数は1以上である必要があります"),
  targetString: Yup.string().required("解説部分を入力してください"),
  commentaryString: Yup.string().required("解説・考察を入力してください"),
});
