import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiPaper: {
      variant: "outlined",
    },
  },
  palette: {
    primary: {
      main: "#6186B9",
    },
    secondary: {
      main: "#B95F85",
    },
    background: {
      default: "#F4F6FA",
    },
    success: {
      main: "#85B95F",
    },
  },
});
