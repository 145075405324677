import { useAuth0 } from "@auth0/auth0-react";
import { ButtonBase, Grid, LinearProgress } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useSelectors } from "../../../hooks/useSelectors";
import { useUrl } from "../../../hooks/useUrl";
import { loadingSelectors } from "../../../module/loading/selector";
import { ProfilePanel } from "../../organisms/ProfilePanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    linearProgress: {
      position: "absolute",
      zIndex: 100,
      width: "100%",
    },
  })
);

export const AppTemplate: FC = ({ children }) => {
  const classes = useStyles();
  const { loading } = useSelectors(loadingSelectors, "loading");
  const { isAuthenticated, user } = useAuth0();
  const { goToRoot } = useUrl();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        color={"inherit"}
        variant={"outlined"}
      >
        {loading && <LinearProgress className={classes.linearProgress} />}
        <Toolbar>
          <Grid
            container
            direction={"row"}
            justify={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Grid container alignItems={"center"}>
                <Grid item onClick={goToRoot}>
                  <ButtonBase>
                    <Typography variant="h6" noWrap>
                      Review
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {isAuthenticated && user && <ProfilePanel user={user} />}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};
