import React, { VFC } from "react";
import "./App.css";
import { AppRouter } from "./components/organisms/AppRouter";
import { Notifier } from "./components/organisms/Notifier";
import { Providers } from "./components/organisms/Providers";
import { AppTemplate } from "./components/templates/AppTemplate";

export const App: VFC = () => {
  return (
    <div className="App">
      <Providers>
        <AppTemplate>
          <Notifier />
          <AppRouter />
        </AppTemplate>
      </Providers>
    </div>
  );
};
