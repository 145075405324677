import { Typography } from "@material-ui/core";
import { VFC } from "react";

export const BookNotFound: VFC = () => {
  return (
    <Typography variant={"h4"} color={"textSecondary"}>
      検索結果が見つかりませんでした
    </Typography>
  );
};
