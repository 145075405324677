import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useCallback, VFC } from "react";
import {
  Proofreading,
  refetchCommentariesQuery,
  refetchProofreadingsQuery,
  useDeleteProofreadingMutation,
} from "../../../graphql/graphql";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: theme.spacing(1),
  },
  original: {
    background: "#F0DDE5",
    paddingLeft: theme.spacing(1),
  },
  proofed: {
    background: "#DDE5F0",
    paddingLeft: theme.spacing(1),
  },
  positionContainer: {
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
}));

type P = {
  proofreading: Proofreading;
};

export const ProofreadingCard: VFC<P> = ({ proofreading }) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const [deleteProofreading] = useDeleteProofreadingMutation();
  const confirm = useConfirm();

  const del = useCallback(() => {
    void confirm({ title: "本当に削除してもよろしいですか？" }).then(() =>
      deleteProofreading({
        variables: {
          id: proofreading.id,
        },
        refetchQueries: [
          refetchProofreadingsQuery({
            isbn: proofreading.isbn,
          }),
        ],
      })
    );
  }, []);

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.positionContainer}
          container
          justify={"space-between"}
        >
          <Grid item>
            <Typography align={"left"}>
              {proofreading.page}P {proofreading.columnOrRow}行・列目
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Typography>
                  {format(new Date(proofreading.createdAt), "yyyy年M月d日")}
                </Typography>
              </Grid>
              {user && user.sub === proofreading.userSub && (
                <Grid item className={classes.deleteButton}>
                  <Tooltip title={"投稿を削除"}>
                    <IconButton size={"small"} onClick={del}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={2}>
            <Typography align={"left"}>元々の文章</Typography>
          </Grid>
          <Grid item xs={10}>
            <Paper className={classes.original}>
              <Typography align={"left"}>
                {proofreading.originalString}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={2} />
          <Grid item xs={10}>
            <ArrowDownwardIcon />
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={2}>
            <Typography align={"left"} variant={"body1"}>
              校正・校閲後
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Paper className={classes.proofed}>
              <Typography align={"left"}>
                {proofreading.proofedString}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
