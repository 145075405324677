import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { BooksQueryVariables } from "../graphql/graphql";
import { hasKey } from "../lib/hasKey";
import { useQueryParams } from "./useQueryParams";

type ReviewTab = "proofreading" | "commentary";

type BookDetailsQueryParams = {
  reviewTab: ReviewTab;
};

export const useUrl = () => {
  const history = useHistory();

  const goToBookDetail = useCallback(
    (isbn: string, { reviewTab }: { reviewTab?: ReviewTab } = {}) =>
      history.push(`/books/${isbn}?reviewTab=${reviewTab || "proofreading"}`),
    []
  );

  const goToSearchBooks = useCallback((v: BooksQueryVariables) => {
    const params = new URLSearchParams();
    Object.entries(v).forEach(([key, value]) => {
      if (value != undefined) {
        params.set(key, value.toString());
      }
    });

    history.push(
      "/search_books" +
        (params.toString().length === 0 ? "" : "?" + params.toString())
    );
  }, []);

  const goToRoot = useCallback(() => history.push("/"), []);

  return {
    goToBookDetail,
    goToSearchBooks,
    goToRoot,
  };
};

export const useBookDetailQueryParams = () => {
  const urlQueryParams = useQueryParams();
  let p: any = {};
  urlQueryParams.forEach((v, k) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    p = { ...p, [k]: v };
  });

  if (hasKey(p, "reviewTab")) {
    return p as BookDetailsQueryParams;
  } else {
    return undefined;
  }
};
