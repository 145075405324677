import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React, { VFC } from "react";
import { SimpleButton } from "../../atoms/SimpleButton";
import { SimpleTextField } from "../../atoms/SimpleTextField";
import { SimpleSelect } from "../../molecules/SimpleSelect";
import { initialValues, Sort, Values } from "./Values";

export type Props = {
  onSearch: (v: Values) => void;
  initialValues?: Values;
};

const useStyle = makeStyles((theme) => ({
  searchPaper: {
    padding: theme.spacing(2),
    maxWidth: 1100,
  },
  submitContainer: {
    marginTop: theme.spacing(1),
  },
}));

const sortItems: { value: Sort; label: string }[] = [
  { value: "Standard", label: "指定なし" },
  { value: "Sales", label: "よく売れている順" },
  { value: "ReleaseDateAsc", label: "発売日が古い順" },
  { value: "ReleaseDateDesc", label: "発売日が新しい順" },
  { value: "PriceAsc", label: "価格が安い順" },
  { value: "PriceDesc", label: "価格が高い順" },
];

export const SearchBox: VFC<Props> = React.memo(
  ({ onSearch, initialValues: initialValuesProp }) => {
    const classes = useStyle();

    return (
      <Paper
        variant={"outlined"}
        component={(props) => <Grid container {...props} />}
        className={classes.searchPaper}
      >
        <Formik
          initialValues={{ ...initialValues, ...initialValuesProp }}
          onSubmit={(v, { setSubmitting }) => {
            onSearch(v);
            setSubmitting(false);
          }}
        >
          {({ values, dirty }) => (
            <Grid item container component={Form} alignItems={"center"}>
              <Grid
                item
                container
                alignItems={"flex-end"}
                justify={"space-between"}
              >
                <Grid item>
                  <SimpleTextField name={"title"} label={"本のタイトル"} />
                </Grid>
                <Grid item>
                  <SimpleTextField name={"author"} label={"著者の名前"} />
                </Grid>
                <Grid item>
                  <SimpleTextField
                    name={"publisherName"}
                    label={"出版社の名前"}
                  />
                </Grid>
                <Grid item>
                  <SimpleTextField name={"isbn"} label={"ISBNコード"} />
                </Grid>
                <Grid item>
                  <SimpleSelect
                    name={"sort"}
                    items={sortItems}
                    label={"並び替え"}
                    width={200}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                justify={"space-between"}
                className={classes.submitContainer}
                alignItems={"center"}
              >
                <Grid item>
                  {
                    <Typography
                      color={
                        Object.values(values).filter((s) => s !== "").length <=
                          1 && dirty
                          ? "secondary"
                          : "textSecondary"
                      }
                    >
                      「本のタイトル」、「著者の名前」、「出版社の名前」もしくは「ISBNコード」のいずれかが検索には必要です
                    </Typography>
                  }
                </Grid>
                <Grid item>
                  <SimpleButton
                    disabled={
                      Object.values(values).filter((s) => s !== "").length <= 1
                    }
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                  >
                    検索
                  </SimpleButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Paper>
    );
  }
);
