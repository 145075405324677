import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import { snackbarActions } from "../../../module/snackbar/slice";
import { store } from "../../../module/store";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach((err) => {
    if (err.extensions && err.extensions["errorCode"]) {
      if (err.extensions["errorCode"] === "CLIENT_ERROR") {
        store.dispatch(
          snackbarActions.enqueueSnackBar({
            message: err.message,
            options: { variant: "error" },
          })
        );
      } else {
        store.dispatch(
          snackbarActions.enqueueSnackBar({
            message: "サーバー側で予期せぬエラーが発生しました",
            options: { variant: "error" },
          })
        );
      }
    }
  });

  if (networkError) {
    console.log(networkError);
    store.dispatch(
      snackbarActions.enqueueSnackBar({
        message: "ネットワークエラー: " + networkError.message,
        options: { variant: "error" },
      })
    );
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URI || "http://localhost:9000/api/graphql",
});

export const ApolloClientProvider: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const client = new ApolloClient({
    link: from([
      errorLink,
      setContext(
        async (_, { headers }: { headers: Record<string, unknown> }) => {
          const token = await getAccessTokenSilently();

          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
            },
          };
        }
      ),
      httpLink,
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
