import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import { useCallback, useEffect, VFC } from "react";
import {
  BooksQueryVariables,
  useBooksLazyQuery,
} from "../../../graphql/graphql";
import { useLoading } from "../../../hooks/useLoading";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useUrl } from "../../../hooks/useUrl";
import { hasKey } from "../../../lib/hasKey";
import { PageHead } from "../../atoms/PageHead";
import { BookSearchResultCard } from "../../organisms/BookSearchResultCard";
import { SearchBox } from "../../organisms/SearchBox";
import { Values } from "../../organisms/SearchBox/Values";
import { BookNotFound } from "./BookNotFound";

const useStyle = makeStyles((theme) => ({
  pageHead: {
    alignSelf: "start",
  },
  searchBoxContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    alignSelf: "center",
  },
}));

const createParams = (
  params: URLSearchParams
): BooksQueryVariables | undefined => {
  let p: any = {};
  params.forEach((v, k) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    p = { ...p, [k]: v };
  });

  if (hasKey(p, "page") && _.has(p, "sort")) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    p["page"] = parseInt(p.page);
    return p as BooksQueryVariables;
  } else {
    return undefined;
  }
};

const toValues = (vars: BooksQueryVariables): Values => ({
  ...vars,
  title: vars.title || undefined,
  publisherName: vars.publisherName || undefined,
  isbn: vars.isbn || undefined,
});

export const SearchPage: VFC = () => {
  const classes = useStyle();
  const [loadBooks, { data, loading }] = useBooksLazyQuery();
  const urlQueryParams = useQueryParams();
  const { goToSearchBooks } = useUrl();

  const searchParams = createParams(urlQueryParams);

  const search = useCallback((v: BooksQueryVariables) => {
    goToSearchBooks(v);
    loadBooks({ variables: v });
  }, []);

  const onSearch = useCallback(
    (v: Values) => {
      search({ ...v, authorName: v.author, page: 1 });
    },
    [search]
  );

  useEffect(() => {
    if (searchParams) {
      loadBooks({
        variables: searchParams,
      });
    }
  }, []);

  useLoading(loading);

  return (
    <Grid container direction="column">
      <Grid item className={classes.pageHead}>
        <PageHead>本を検索</PageHead>
      </Grid>
      <Grid item className={classes.searchBoxContainer}>
        <SearchBox
          onSearch={onSearch}
          initialValues={searchParams && toValues(searchParams)}
        />
      </Grid>
      <Grid container item spacing={2}>
        {data && data?.books.books.length !== 0
          ? data.books.books.map((book) => (
              <Grid item key={book.isbn}>
                <BookSearchResultCard book={book} />
              </Grid>
            ))
          : data?.books.books.length === 0 && (
              <Grid item xs={12}>
                <BookNotFound />
              </Grid>
            )}
      </Grid>
      {data && (
        <Grid item className={classes.paginationContainer}>
          <Pagination
            page={data.books.page}
            shape={"rounded"}
            count={data.books.pageCount}
            onChange={(_, page) =>
              searchParams && search({ ...searchParams, page })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
