import { makeStyles } from "@material-ui/core/styles";
import { VFC } from "react";
import { LogoutButton } from "../../atoms/LogoutButton";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
    width: 200,
  },
}));

export const Panel: VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LogoutButton />
    </div>
  );
};
