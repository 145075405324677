import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loadingReducers } from "./loading/slice";
import { snackbarReducer } from "./snackbar/slice";

const reducer = combineReducers({
  loading: loadingReducers,
  snackbar: snackbarReducer,
});

export type RootState = ReturnType<typeof reducer>;

const store = configureStore({
  reducer,
});

export { reducer, store };
