import * as Yup from "yup";

export type Values = {
  page: string;
  originalString: string;
  proofedString: string;
  columnOrRow: number;
};

export const initialValues: Values = {
  page: "",
  originalString: "",
  proofedString: "",
  columnOrRow: 1,
};

export const schema = Yup.object().shape({
  page: Yup.string().required("該当のページを入力してください"),
  originalString: Yup.string().required("元の文章を入力してください"),
  proofedString: Yup.string().required("校正・校閲後の文章を入力してください"),
  columnOrRow: Yup.number().min(1, "ページ数は1以上である必要があります"),
});
