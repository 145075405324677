import { useEffect } from "react";
import { loadingActions } from "../module/loading/slice";
import { useBoundActions } from "./useBoundActions";

export const useLoading = (on: boolean): void => {
  const { turnOffLoading, turnOnLoading } = useBoundActions(loadingActions);

  useEffect(() => {
    if (on) {
      turnOnLoading();
    } else {
      turnOffLoading();
    }
  }, [on]);
};
