import { SnackbarContentProps } from "@material-ui/core/SnackbarContent";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsObject, SnackbarKey } from "notistack";

const name = "snackbar";

export type SnackbarState = SnackbarContentProps & {
  options?: OptionsObject;
};

const initialState: (SnackbarState & { key: SnackbarKey })[] = [];

const slice = createSlice({
  name,
  initialState,
  reducers: {
    enqueueSnackBar: (state, action: PayloadAction<SnackbarState>) => [
      ...state,
      { key: new Date().getTime() + Math.random(), ...action.payload },
    ],
    removeSnackbar: (state, action: PayloadAction<SnackbarKey>) =>
      state.filter((noti) => noti.key !== action.payload),
  },
});

export const snackbarActions = slice.actions;

export const snackbarReducer = slice.reducer;
