import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { VFC } from "react";
import { Route, RouteProps } from "react-router-dom";

export const ProtectedRoute: VFC<RouteProps> = ({ component, ...props }) => {
  const p = component
    ? { ...props, component: withAuthenticationRequired(component) }
    : props;

  return <Route {...p} />;
};
