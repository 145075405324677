import { TextFieldProps } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { VFC } from "react";

type Props = {
  name: string;
  label: string;
} & TextFieldProps;

export const SimpleTextField: VFC<Props> = ({ name, label, ...props }) => {
  return (
    <Field
      component={TextField}
      name={name}
      variant={"outlined"}
      size={"small"}
      label={label}
      {...props}
    />
  );
};
