import { Typography } from "@material-ui/core";
import { VFC } from "react";

type Props = {
  children: string;
};

export const PageHead: VFC<Props> = ({ children }) => {
  return <Typography variant={"h5"}>{children}</Typography>;
};
