import { MuiThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { FC } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { theme } from "../../../consts/theme";
import { store } from "../../../module/store";
import { ApolloClientProvider } from "./ApolloClientProvider";
import { AuthProvider } from "./AuthProvider";

export const appHistory = createBrowserHistory();

const usetyles = makeStyles((theme) => ({
  successSnack: {
    backgroundColor: theme.palette.success.main,
  },
  errorSnack: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const Providers: FC = ({ children }) => {
  const classes = usetyles();

  return (
    <AuthProvider>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.successSnack,
              variantError: classes.errorSnack,
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <ConfirmProvider>
              <Router history={appHistory}>
                <ApolloClientProvider>{children}</ApolloClientProvider>
              </Router>
            </ConfirmProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </Provider>
    </AuthProvider>
  );
};
