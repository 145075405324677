import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useCallback, VFC } from "react";
import {
  Commentary,
  refetchCommentariesQuery,
  useDeleteCommentaryMutation,
} from "../../../graphql/graphql";

type P = {
  commentary: Commentary;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    borderLeftWidth: theme.spacing(1),
    borderLeftColor: theme.palette.primary.main,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const CommentaryCard: VFC<P> = ({ commentary }) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const [deleteCommentary] = useDeleteCommentaryMutation();
  const confirm = useConfirm();

  const del = useCallback(() => {
    void confirm({ title: "本当に削除してもよろしいですか？" }).then(() =>
      deleteCommentary({
        variables: {
          id: commentary.id,
        },
        refetchQueries: [
          refetchCommentariesQuery({
            isbn: commentary.isbn,
          }),
        ],
      })
    );
  }, []);

  return (
    <Paper className={classes.paper}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item container justify={"space-between"}>
          <Grid item>
            <Typography align={"left"}>
              {commentary.page}P {commentary.columnOrRow}行・列目
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Typography>
                  {format(new Date(commentary.createdAt), "yyyy年M月d日")}
                </Typography>
              </Grid>
              {user && user.sub === commentary.userSub && (
                <Grid item className={classes.deleteButton}>
                  <Tooltip title={"投稿を削除"}>
                    <IconButton size={"small"} onClick={del}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            focused={false}
            fullWidth
            variant={"outlined"}
            label={"該当箇所"}
            value={commentary.targetString}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            focused={false}
            label={"解説・考察"}
            value={commentary.commentaryString}
            variant={"outlined"}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
