import { Divider, Paper, Tab, Tabs } from "@material-ui/core";
import { useState, VFC } from "react";
import { useBookDetailQueryParams, useUrl } from "../../../hooks/useUrl";
import { CommentaryTab } from "./CommentaryTab";
import { ProofreadingTab } from "./ProofreadingTab";

type P = {
  isbn: string;
};

export const ReviewTab: VFC<P> = ({ isbn }) => {
  const params = useBookDetailQueryParams();
  const { goToBookDetail } = useUrl();

  const tab = params?.reviewTab || "proofreading";
  const tabIndex = tab === "proofreading" ? 0 : 1;

  return (
    <Paper>
      <Tabs
        variant={"fullWidth"}
        onChange={(_, index) => {
          if (index === 0) {
            goToBookDetail(isbn, { reviewTab: "proofreading" });
          } else if (index === 1) {
            goToBookDetail(isbn, { reviewTab: "commentary" });
          }
        }}
        value={tabIndex}
        indicatorColor={"primary"}
      >
        <Tab label="校正・校閲" />
        <Tab label="解説・考察" />
      </Tabs>
      <Divider />
      {tab === "proofreading" && <ProofreadingTab />}
      {tab === "commentary" && <CommentaryTab />}
    </Paper>
  );
};
